import { render, staticRenderFns } from "./BoardContainer.vue?vue&type=template&id=6f856383&scoped=true&lang=pug&"
var script = {}
import style0 from "./BoardContainer.vue?vue&type=style&index=0&id=6f856383&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f856383",
  null
  
)

component.options.__file = "BoardContainer.vue"
export default component.exports