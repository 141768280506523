import { render, staticRenderFns } from "./FloatingOptions.vue?vue&type=template&id=83a5e100&scoped=true&lang=pug&"
import script from "./FloatingOptions.vue?vue&type=script&lang=js&"
export * from "./FloatingOptions.vue?vue&type=script&lang=js&"
import style0 from "./FloatingOptions.vue?vue&type=style&index=0&id=83a5e100&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83a5e100",
  null
  
)

component.options.__file = "FloatingOptions.vue"
export default component.exports