import { render, staticRenderFns } from "./GameOptions.vue?vue&type=template&id=18b60f8f&lang=pug&"
import script from "./GameOptions.vue?vue&type=script&lang=js&"
export * from "./GameOptions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "GameOptions.vue"
export default component.exports